import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/UI/Layout"
import Navbar from "../../components/UI/Navbar"
import Seo from "../../components/Seo"
import ServicePage from "../../components/templates/ServicePage"

const query = graphql`
  {
    allContentfulInstallationImages(
      filter: {
        metadata: {
          tags: {
            elemMatch: { contentful_id: { eq: "farmFireSuppressionPonds" } }
          }
        }
      }
    ) {
      nodes {
        id
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
          id
          title
        }
      }
    }
  }
`

export default function FireSuppressionPondsPage() {
  const title = `Farm & Fire Suppression Pond` // Singular (not plural)
  const {
    allContentfulInstallationImages: { nodes: image },
  } = useStaticQuery(query)

  const imageList = image[0].image

  return (
    <Layout>
      <Navbar />
      <Seo
        title={`${title}s`}
        description={`Looking to build a ${title} to combat drought and wildfires in Rogue Valley? We can install pond liners for your large pond project.`}
      />

      <ServicePage
        title={title}
        imageList={imageList}
        img={
          <StaticImage
            src="../../assets/images/Fire-Suppression-Pond-Liner_Andreatta-Waterscapes_Rogue-Valley-Oregon.jpg"
            quality={90}
            loading="eager"
            style={{
              position: "absolute",
              width: "100%",
              zIndex: 1,
              height: "21rem",
            }}
            objectFit="cover"
            objectPosition="50% 50%"
            alt="Pondless Waterfall Medford Oregon"
          />
        }
        tagline={`Capture and store water for when and where you need it`}
        body={[
          {
            id: 0,
            type: "paragraph",
            content: `We can install liners for your large pond project as well as consult and help design your project. Whether it’s for irrigation, fire suppression, or a body of water large enough to take your canoe out with the grandkids. `,
          },
          {
            id: 1,
            type: "paragraph",
            content: `With the recent droughts, many people with farms and larger properties are turning to water retention for irrigation and fire suppression. These large bodies of water require waterproofing, and while there are various methods of accomplishing this, we install a liner which can be as small or large as needed for your pond.`,
          },
          {
            id: 2,
            type: "paragraph",
            content: `Fire retention ponds are more important than ever as drought and the threat of wildfires has directly affected our communities here in the Rogue Valley. `,
          },
        ]}
      />
    </Layout>
  )
}
